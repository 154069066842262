import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { Box, Text, Card, Flex, Button } from "theme-ui";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Section from "@components/Section";
import Seo from "@widgets/Seo";

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`,
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4,
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    " > div + div": {
      ml: [0, 0, 5],
    },
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`],
  },
};

export default (props) => {
  const data = useStaticQuery(aboutQuery);
  const image = getImage(data.avatar);

  return (
    <Layout {...props}>
      <Seo title="About" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="About"
            subheader="Hi, I'm Agathe, a growth product manager specialized in B2B API products, working remotely."
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button} as={Link} to="/contact">
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant="p">
                  As an engineer, I love solving problems and smoothly-working
                  products. As a natural organizer, I trained to become a
                  product manager.
                </Text>
                <Text variant="p">
                  I've spent the past 10 years working for multinational
                  companies, non-for-profit organizations, local businesses and
                  e-commerce making digital products and services better through
                  the power of user experience and process improvement in agile
                  frameworks.
                </Text>
                <Text variant="p">
                  My subjects of predilection are:
                  <ul>
                    <li>Monetization and pricing optimization of APIs</li>
                    <li>Optimization of the onboarding flow and TTFHW</li>
                    <li>
                      Building the API strategy and integrations eco-system
                    </li>
                  </ul>
                </Text>
                <Text variant="p">
                  I decided to start writing to share my learnings and
                  experiments while scaling products aimed at developer
                  audiences. Here, you'll read about managing API products in a
                  product-led-growth model.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title="Strategic thinker">
                <Card variant="paper">
                  I handle long term product vision as well as day-to-day
                  operations by doing discovery, market research, and competitor
                  analysis, I can set a 12-month roadmap and convert it into
                  daily and weekly tasks for product teams.
                </Card>
              </Section>
              <Divider />
              <Section title="Cross-functional">
                <Card variant="paper">
                  I keep teams working effectively and efficiently and build
                  shared objectives with key company initiatives. I also manage
                  the integrations portion of products and optimize integrations
                  quality strategy, like SDK maintenance.
                </Card>
              </Section>
              <Divider />
              <Section title="Data-driven">
                <Card variant="paper">
                  I optimize my team's process as much as the product itself by
                  using data to uncover opportunities and understand what levers
                  to pull to drive business results.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  );
};

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`;
